/* src/components/menu/VideoModal.css */
.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 20px;
}

.video-modal-content {
    position: relative;
    background-color: #000;
    width: 100%;
    max-width: 800px;
    aspect-ratio: 16/9;
    margin: auto;
}

.video-player-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.video-modal-close {
    position: absolute;
    top: -35px;
    right: 0;
    background: none;
    border: none;
    color: white;
    font-size: 28px;
    cursor: pointer;
    padding: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
}

@media (max-width: 768px) {
    .video-modal-overlay {
        padding: 15px;
    }

    .video-modal-content {
        width: 100%;
        max-height: 80vh;
    }

    .video-modal-close {
        top: -35px;
        right: 0;
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .video-modal-overlay {
        padding: 10px;
    }

    .video-modal-content {
        width: 100%;
        aspect-ratio: 16/9;
    }
}

@media (max-height: 500px) and (orientation: landscape) {
    .video-modal-content {
        width: auto;
        height: 85vh;
        aspect-ratio: 16/9;
    }
}