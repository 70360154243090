.contenedor-menu {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.menu-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.menu-dish-item {
  display: flex;
  width: 100%;
}

.menu-dish-content {
  flex: 1;
}

.menu-dish-name {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.menu-dish-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.menu-price {
  color: #000000;
  font-family: 'Helvetica-bold';
  font-weight: 400;
  font-size: 1.9em;
  text-align: center;
  display: inline-flex;
  align-items: center;
  width: auto;
  margin: 0 auto;
  margin-top: 15px;

}

.menu-price::before,
.menu-price::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000000 !important;
  display: inline-flex;
  width: 40px;

}


.menu-price::before {
  margin-right: .35em;
}

.menu-price::after {
  margin-left: .35em;
}

.menu-price::not(:empty)::before {
  margin-right: .55em !important;
}

.menu-price::not(:empty)::after {
  margin-left: .55em;
}

.menu-alergenos {
  font-style: italic;
  color: #000;
}

.dish-wrapper {
  border-bottom: 1px solid #ddd;
  padding: 8px 0;
}

.carta-header {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}

.carta-container {
  padding: 16px;
}

.close-button {
  cursor: pointer;
}

/* Estilos para el video */
.video-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.video-icon {
  color: #333;
}

.video-icon:hover {
  color: #666;
}

.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.video-modal-content {
  position: relative;
  background-color: #000;
  padding: 20px;
  border-radius: 8px;
  max-width: 90vw;
  max-height: 90vh;
}

.video-modal-close {
  position: absolute;
  top: -30px;
  right: -30px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}